import type { AnyObject } from '@florencecard-lib/type-utils';
import { parse, parseUrl } from 'query-string';

/**
 * GET 파라미터로 전달되는 쿼리 스트링을 제작합니다.
 * 주의: params가 비었을 경우, 빈 문자열을 반환하지만, params에 키-값 쌍이 존재하면 `?`가 앞에 추가됩니다.
 * 예) { a: 1, b: 2, c: '가나다' } => '?a=1&b=2&c=%EA%B0%80%EB%82%98%EB%8B%A4',
 * {} => ''
 */
export function createQueryString(params: AnyObject, appendQuestionMark = true) {
  const queryString = (Object.keys(params) as Array<keyof typeof params>)
    .filter((key) => params[key] != null)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((key) => `${key}=${encodeURIComponent(params[key]!)}`)
    .join('&');

  if (!queryString) {
    return '';
  }

  return `${appendQuestionMark ? '?' : ''}${queryString}`;
}

/**
 * URL 쿼리 문자열을 파싱하여 타입 파라미터 `Result` 형식으로 반환합니다.
 * @param [queryString=location.search] - 파싱 대상 문자열(`?foo=bar` 형태), 기본값 `location.search`
 */
export function parseQueryString<Result>(
  queryString: string = typeof window.location !== 'undefined' ? window.location.search : '',
): Result {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return parse(queryString) as any;
}

export function getQueryString(name: string): string | undefined;
export function getQueryString<T>(name: string, parser: (val: string) => T): T | undefined;

export function getQueryString<T = string>(name: string, parser?: (val: string) => T) {
  const value = parseQueryString<{ [name: string]: string | undefined }>()[name];

  if (parser == null || value === undefined) {
    return value;
  } else {
    return parser(value);
  }
}

export function appendQueryString(url: string, queryParams: AnyObject) {
  const path = url.split('?')[0];

  const { query: originParams } = parseUrl(url);
  const mergedQueryParams = {
    ...originParams,
    ...queryParams,
  };

  return `${path}${createQueryString(mergedQueryParams)}`;
}
